import { T } from "@kanpla/system";
import { useIntercom } from "react-use-intercom";
import { Heading } from "../components/Layout";

export const InvitationBlock = () => {
  const { show } = useIntercom();
  return (
    <div className="flex flex-col gap-4">
      <Heading>
        <T _str="Sign up with Invitation link" />
      </Heading>
      <p>
        <T _str="Go to your inbox and find the email that has invited you to this platform. Follow the link in the email to create your account." />
      </p>
      <p>
        <T
          _str="If you haven't received your invitation email, please {contactSupport} to resend it."
          contactSupport={
            <button onClick={show} className="underline">
              <T _str="contact support" />
            </button>
          }
        />
      </p>
    </div>
  );
};
