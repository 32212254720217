import { faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  KanplaError,
  T,
  auth,
  useNativeKeyboardOffset,
  useT,
} from "@kanpla/system";
import { Button, Input, message } from "@kanpla/ui";
import { URL_SIGNUP } from "apps/frontend/pages/signup";
import { SignInMethod, fetchSignInMethodsForEmail } from "firebase/auth";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { useForm } from "react-hook-form";
import { useContainer } from "unstated-next";
import { z } from "zod";
import { AppContext } from "../contextProvider";
import { Heading } from "../signup/components/Layout";
import { providerSetup } from "../signup/inputViews/ProvidersPick";

type EmailError = "EMAIL_NOT_FOUND" | "INVALID_EMAIL";

export function onSignInError(t: (m: string) => string, err: any) {
  // User isn't created
  if (err?.code === "functions/not-found") {
    const noUserError = new KanplaError(
      "auth/user-not-found",
      "Firebase: Error (auth/user-not-found)."
    );

    message.error(noUserError.message);
    return;
  }

  // Can't log user in
  if (err?.status === 401) {
    console.error(err);
    message.error(
      t(
        "We can't log you into the system, because you already have an account at another partner. Use a different email address."
      )
    );
    return;
  }

  const newError = new KanplaError(err?.code, err?.message);
  console.error(err);
  message.error(newError.message);
}

const schema = z.object({
  email: z.string().email("INVALID_EMAIL"),
  // mark optional to allow for email validation. Minimum length in lieu of `required`
  password: z.string().min(1, "PASSWORD_MISSING").optional(),
});

type FormData = z.infer<typeof schema>;

export const Login = () => {
  const t = useT();
  const { auth: authHook } = useContainer(AppContext);
  useNativeKeyboardOffset("HUGE");

  const { register, handleSubmit, formState, watch, setError } =
    useForm<FormData>({
      resolver: zodResolver(schema, { async: true }, { mode: "async" }),
    });

  const { isSubmitting, errors } = formState;
  const [methodsRecord, setMethodsRecord] = React.useState<
    Record<string, string[]>
  >({});
  const [loadingMethods, setLoadingMethods] = React.useState(false);
  const onEmailSubmit = async (email: string) => {
    const cleanedEmail = email.trim().toLowerCase();
    if (cleanedEmail) {
      setLoadingMethods(true);
      try {
        const methods =
          methodsRecord[cleanedEmail] ??
          (await fetchSignInMethodsForEmail(auth, cleanedEmail));

        if (!methods.length) {
          setError("email", { message: "EMAIL_NOT_FOUND" });
        }
        setMethodsRecord({ ...methodsRecord, [cleanedEmail]: methods });
      } catch (err: any) {
        setError("email", err.message);
      }
      setLoadingMethods(false);
    }
  };

  const onSubmit = async (data: FormData) => {
    if (!data.password) return onEmailSubmit(data.email);
    try {
      await authHook.signIn(data.email, data.password);
    } catch (err: any) {
      // copy logic from old flow: to be refactored
      onSignInError(t, err);
    }
  };

  const cleanedEmail = watch("email")?.trim().toLowerCase();
  const methods = methodsRecord[cleanedEmail] ?? [];
  return (
    <form className="flex flex-col gap-3" onSubmit={handleSubmit(onSubmit)}>
      <Heading>{t("Login")}</Heading>
      <Input.Email
        {...register("email")}
        autoComplete="email"
        value={watch("email")}
        placeholder={t("Enter your email address")}
        required
        error={!!errors.email}
      />
      {errors.email?.message && (
        <p className="text-danger-main">
          <EmailErrorMessage error={errors.email.message} />
        </p>
      )}
      {!methods.length && (
        <Button
          type="primary"
          shape="soft"
          htmlType="submit"
          disabled={!cleanedEmail}
          loading={loadingMethods}
          className="mt-3 md:mt-0"
        >
          <T _str="Continue" />
        </Button>
      )}

      {methods.map((method) => {
        switch (method) {
          case SignInMethod.EMAIL_PASSWORD:
            return (
              <React.Fragment key={method}>
                <Input.Password
                  {...register("password")}
                  autoComplete="current-password"
                  value={watch("password")}
                  placeholder={t("Enter your password")}
                  required
                  error={!!errors.password}
                />
                {errors.password && (
                  <p className="text-danger-main">
                    {errors.password.message === "PASSWORD_MISSING" ? (
                      <T _str="Please enter your password." />
                    ) : (
                      errors.password.message
                    )}
                  </p>
                )}
                <Link href="/resetPassword" legacyBehavior>
                  <a>
                    <T _str="Forgot password?" />
                  </a>
                </Link>
                <Button
                  type="primary"
                  shape="soft"
                  htmlType="submit"
                  disabled={!watch("password")}
                  loading={isSubmitting}
                  loadingText={t("Please wait...")}
                  className="mt-3 md:mt-0"
                >
                  <T _str="Continue" />
                </Button>
              </React.Fragment>
            );
          default:
            return <MicrosoftLogin key={method} email={cleanedEmail} />;
        }
      })}
      <div className="mt-6 text-text-secondary">
        <T _str="Don't have an account? " />
        <Link href={URL_SIGNUP} legacyBehavior>
          <a className="underline underline-offset-2">{t("Sign up here")}</a>
        </Link>
      </div>
    </form>
  );
};

const MicrosoftLogin = ({
  method = "microsoft.com",
  email,
}: {
  method?: string;
  email: string;
}) => {
  const router = useRouter();
  const t = useT();
  return (
    <Button
      onClick={() => {
        // see apps/frontend/pages/login/__/microsoft.tsx#L43
        sessionStorage.setItem("kanpla-login-email", email);
        router.push("/login/__/microsoft");
      }}
      size="large"
      htmlType="button"
      className="flex mx-auto w-full mt-3 md:mt-0"
      icon={faMicrosoft}
      dataCy="btn-multi-login-microsoft"
    >
      {t("Login with {value}", {
        value: providerSetup[method]?.name || method,
      })}
    </Button>
  );
};

const EmailErrorMessage = ({ error }: { error: EmailError | string }) => {
  switch (error) {
    case "EMAIL_NOT_FOUND":
      return <T _str="This email doesn't exist." />;
    case "INVALID_EMAIL":
      return <T _str="Invalid email address" />;
    default:
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <>{error}</>;
  }
};
