import { getLocationHostname, useNativeKeyboardOffset } from "@kanpla/system";
import CreateUser, { Step } from "apps/frontend/components/signup/CreateUser";
import { domainSignupConfig } from "apps/frontend/lib/signup/domains";
import { GetServerSideProps, NextPage } from "next";

export const URL_SIGNUP = "/signup"; // Keep in sync with the file location

type Props = {
  initialStep: Step;
};
const Page: NextPage<Props> = ({ initialStep }) => {
  useNativeKeyboardOffset("HUGE");

  return <CreateUser intialStep={initialStep} />;
};
export default Page;

export const getServerSideProps: GetServerSideProps<Props> = async ({
  req,
}) => {
  if (!req.headers.host) return { notFound: true };
  const host = req.headers.host.split(":")[0];
  const initialStep = getInitialStepFromHost(host);
  return {
    props: { initialStep },
  };
};

function getInitialStepFromHost(rawHost: string): Step {
  const host = getLocationHostname(rawHost);
  const hasSwitch = domainSignupConfig.switch.includes(host);
  const hasList = domainSignupConfig.list.includes(host);
  const hasInvitationBlock = domainSignupConfig.invitationBlock.includes(host);

  if (hasInvitationBlock) return { type: "invitation-block" };
  if (hasSwitch) return { type: "switch" };
  if (hasList) return { type: "school" };
  return { type: "workEmail" };
}
