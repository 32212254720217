import { zodResolver } from "@hookform/resolvers/zod";
import { callInternalApi, getErrorMessage, t, T, useT } from "@kanpla/system";
import { DomainInfo, School, ValueOf } from "@kanpla/types";
import { Button, Input } from "@kanpla/ui";
import { useQueryClient } from "@tanstack/react-query";
import {
  ValidateEmailOptions,
  ValidateEmailResponse,
} from "apps/frontend/pages/api/internal/signup/validateEmail";
import { URL_LOGIN } from "apps/frontend/pages/login";
import { uniqBy } from "lodash";
import Link from "next/link";
import { useForm } from "react-hook-form";
import { useContainer } from "unstated-next";
import { z } from "zod";
import { AppContext } from "../../contextProvider";
import { InputWithMessage } from "../components/InputWithMessage";
import { Heading } from "../components/Layout";
import { Err, Ok, Result } from "../util/Result";

// Validation
export const EMAIL_FIELD = "email";
export const EMAIL_SCHEMA = z
  .string()
  .refine(
    (val) =>
      new RegExp(
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      ).test(val),
    { message: t("Please use a valid email.") }
  );
const FORM_SCHEMA = z.object({
  [EMAIL_FIELD]: EMAIL_SCHEMA,
});
type FormFields = z.infer<typeof FORM_SCHEMA>;

const ERRORS = {
  EMAIL_EXISTS: "EMAIL_EXISTS",
  INTERNAL: "INTERNAL",
} as const;

type EmailErrorType = ValueOf<typeof ERRORS>;
type EmailError = { type: EmailErrorType; message?: string };

const EmailErrorMessage = ({ error }: { error: EmailError }) => {
  const t = useT();
  return (
    <p className="text-danger-main">
      {error.type === ERRORS.EMAIL_EXISTS ? (
        <>
          <T _str="This email is already registered." />{" "}
          <Link href={URL_LOGIN}>{t("Log in")}</Link>
        </>
      ) : (
        error.message ?? <T _str="Something went wrong" />
      )}
    </p>
  );
};

/**
 * Callback to be called when the email is submitted.
 *
 * @param email The email address
 * @param schoolIds The school IDs associated with the email domain
 */
export type OnEmailInput = (email: string, schoolIds: School["id"][]) => void;

type WorkEmailProps = {
  onSubmit: OnEmailInput;
  emailValue?: string;
};

/**
 * Collects an email address and possibly a location linked to the email address.
 */
export const WorkEmailAndLocation = ({
  onSubmit,
  emailValue,
}: WorkEmailProps) => {
  const { handleSubmit, register, formState, setError, watch } =
    useForm<FormFields>({
      resolver: zodResolver(FORM_SCHEMA),
      mode: "onSubmit",
      values: emailValue ? { [EMAIL_FIELD]: emailValue } : undefined,
    });

  // Get email validation function
  const validateEmail = useEmailValidation();

  const submitHandler = async (formData: FormFields) => {
    const response = await validateEmail(formData[EMAIL_FIELD]);
    if (response.isErr) {
      setError(EMAIL_FIELD, response.error);
      return;
    }
    const domains = response.data;

    // Extract school IDs from the domains
    const schoolIds = uniqBy(domains, (d) => d.schoolId).flatMap((d) =>
      d.schoolId ? [d.schoolId] : []
    );

    onSubmit(formData[EMAIL_FIELD], schoolIds);
  };

  const { isValidating, isSubmitting } = formState;

  const emailError = formState.errors[EMAIL_FIELD];

  return (
    <form
      className="flex flex-col gap-4"
      onSubmit={handleSubmit(submitHandler)}
    >
      <Heading>
        <T _str="Signup" />
      </Heading>
      <p>
        <T
          _str="Start by writing your {underline}"
          underline={
            <span className="underline">
              <T _str="work email" />
            </span>
          }
        />
      </p>
      {/* maybe extract Input.Email + errors + useEmailValidation in ../components/email ? */}
      <InputWithMessage
        input={
          <Input.Email
            placeholder={t("Enter your email address")}
            required
            dataCy="new-email-signup"
            error={!!formState.errors[EMAIL_FIELD]}
            {...register(EMAIL_FIELD)}
          />
        }
        isError={!!emailError}
        message={
          emailError ? (
            <EmailErrorMessage error={emailError as EmailError} />
          ) : null
        }
      />
      <Button
        htmlType="submit"
        type="primary"
        shape="soft"
        disabled={watch(EMAIL_FIELD) === ""}
        loading={isValidating || isSubmitting}
      >
        <T _str="Continue" />
      </Button>
    </form>
  );
};

/**
 * @returns Function that validates an email address and returns the
 * {@link DomainInfo DomainInfo[]} associated with the email domain.
 */
function useEmailValidation() {
  const client = useQueryClient();
  const { supplier } = useContainer(AppContext);

  const validateEmail = async (
    email: string
  ): Promise<Result<DomainInfo[], EmailError>> => {
    let domainOrDomains;
    try {
      domainOrDomains = await client.fetchQuery({
        queryKey: ["signup/validateEmail", { email }],
        queryFn: () => {
          return callInternalApi<ValidateEmailOptions, ValidateEmailResponse>(
            "signup/validateEmail",
            {
              email,
              onlyCompanySignup: true, // Abort if no company is found
              // Not sure where `supplier` comes from & if it's correctly used here.
              // Just following the existing code.
              partnerId: supplier?.partnerId,
              avoidProviders: false, // User did not specifically avoid signup with a provider
              schoolId: undefined,
            }
          );
        },
      });
    } catch (error) {
      return Err({
        type: "INTERNAL",
        message: getErrorMessage(error),
      });
    }

    if (!domainOrDomains) return Err({ type: "INTERNAL" });

    const domains = [domainOrDomains].flat();

    const emailAlreadyUsed = domains.some((d) => d.alreadyExists);
    if (emailAlreadyUsed) {
      return Err({ type: "EMAIL_EXISTS" });
    }

    return Ok(domains);
  };

  return validateEmail;
}
