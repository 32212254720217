import {
  IconDefinition,
  faMicrosoft,
} from "@fortawesome/free-brands-svg-icons";
import { T, callInternalApi } from "@kanpla/system";
import { ChildSelector, Providers } from "@kanpla/types";
import { Button, Divider, Spinner } from "@kanpla/ui";
import { useQuery } from "@tanstack/react-query";
import {
  GetSignupDomainArgs,
  GetSignupDomainResponse,
} from "apps/frontend/pages/api/internal/signup/get-signup-domain";
import { useRouter } from "next/router";
import React from "react";
import { Heading } from "../components/Layout";
import { setProviderSignupInit } from "../util/providerSignupInit";

type Props = {
  email: string;
  schoolId: string;
  selectors?: ChildSelector;
  onSkip: () => void;
};

export const providerSetup: {
  [key: string]: {
    name: string;
    id: string;
    icon: IconDefinition;
  };
} = {
  "microsoft.com": {
    name: "Microsoft",
    id: "microsoft.com",
    icon: faMicrosoft,
  },
};

export const ProvidersPick = ({
  schoolId,
  email,
  selectors,
  onSkip,
}: Props) => {
  const query = useSignupDomainProvidersQuery(email, schoolId, selectors);
  const router = useRouter();

  if (query.isLoading) {
    return <Spinner />;
  }

  if (!query.providers.length) {
    onSkip();
    return null;
  }

  const handleProviderPick = (provider: Providers) => {
    setProviderSignupInit({
      email,
      schoolId,
      selectors,
      provider,
    });
    router.push("/signup/provider");
  };

  const providersCtas = query.providers.map((provider) => ({
    id: providerSetup[provider.name].id || provider.name,
    name: providerSetup[provider.name]?.name || provider.name,
    icon: providerSetup[provider.name].icon,
    dataCy: `btn-provider-${provider.name}`,
    provider,
  }));

  const anyProviderDisablesPassword = query.providers.some(
    (p) => p.disablePassword
  );

  return (
    <div className="flex flex-col gap-4">
      <Heading>
        <T _str="Access your canteen" />
      </Heading>
      <div className="flex flex-col gap-3 items-center">
        {providersCtas.map(({ name, id, dataCy, icon, provider }) => (
          <Button
            key={id}
            onClick={() => handleProviderPick(provider)}
            size="large"
            className="self-stretch flex justify-center"
            icon={icon}
            dataCy={dataCy}
          >
            <T _str="Sign up with {provider}" provider={name} />
          </Button>
        ))}
        <p className="text-sm text-text-secondary">
          <T _str="You will be redirected" />
        </p>
      </div>
      <Divider />
      {!anyProviderDisablesPassword && (
        <div className="flex flex-col gap-3 items-center">
          <Button
            onClick={onSkip}
            dataCy="btn-signup-use-password"
            shape="solid"
          >
            <T _str="Use password" />
          </Button>
          <p className="text-sm text-text-secondary">
            <T _str="Create an account manually" />
          </p>
        </div>
      )}
    </div>
  );
};

function useSignupDomainProvidersQuery(
  email: string,
  schoolId: string,
  selectors?: ChildSelector
) {
  const query = useQuery<GetSignupDomainResponse, Error>(
    ["signup/get-signup-domain", email, schoolId, selectors],
    () =>
      callInternalApi<GetSignupDomainArgs, GetSignupDomainResponse>(
        "signup/get-signup-domain",
        {
          email,
          schoolId,
          selectors,
        }
      )
  );
  const providers = query.data?.providers ?? [];
  return { ...query, providers };
}
