import { useT } from "@kanpla/system";
import { Button, Spinner, message } from "@kanpla/ui";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../../components/contextProvider";
import { Login } from "../../components/login/Login";
import { GoBackFindHelpNavigation } from "../../components/signup/components/GoBackFindHelpNavigation";
import Layout from "../../components/signup/components/Layout";

export const URL_LOGIN = "/login"; // Keep in sync with the file location

export const useRedirectToLogin = () => {
  const router = useRouter();
  return () =>
    router.push({
      pathname: URL_LOGIN,
      query: { redirectUrl: router.asPath },
    });
};

export const useRedirectUrl = () => {
  const router = useRouter();
  return `${URL_LOGIN}?redirectUrl=${encodeURIComponent(router.asPath)}`;
};

const LoginPage = () => {
  const router = useRouter();
  const { auth } = useContainer(AppContext);
  const isLoggedIn = Boolean(!auth?.loading && auth?.user?.uid);

  const onLogout = () => auth.signOut().catch((e) => message.error(e?.message));

  return (
    <Layout>
      <GoBackFindHelpNavigation
        onGoBack={router.back}
        className="order-1 mt-auto sm:-order-1 sm:mt-0"
      />
      <Layout.Inner className="flex-1">
        {isLoggedIn ? <Loader onLogout={onLogout} /> : <Login />}
      </Layout.Inner>
    </Layout>
  );
};

const Loader = ({ onLogout }: { onLogout: () => void }) => {
  const t = useT();
  const [timedOut, setTimedOut] = useState(false);
  React.useEffect(() => {
    const timeout = setTimeout(() => setTimedOut(true), 5000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="flex flex-col gap-4 h-full justify-center items-center text-primary-main">
      <Spinner useCurrentColor size="medium" />
      {timedOut && (
        <>
          <p className="text-text-secondary text-sm mt-4">
            {t("Experiencing issues with login? Click here:")}
          </p>
          <Button
            type="primary"
            shape="solid"
            className="self-stretch"
            onClick={onLogout}
            dataCy="btn-logout"
          >
            {t("Log out")}
          </Button>
        </>
      )}
    </div>
  );
};
export default LoginPage;
