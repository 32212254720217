import { T, callInternalApi, tx } from "@kanpla/system";
import { ChildSelector, Scope } from "@kanpla/types";
import { Spinner } from "@kanpla/ui";
import { useMutation } from "@tanstack/react-query";
import { useStack } from "apps/frontend/lib/useStack";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { useContainer } from "unstated-next";
import {
  CreateChildUserPropsType,
  CreateChildUserReturnType,
} from "../../pages/api/internal/user/new";
import { AppContext } from "../contextProvider";
import { GoBackFindHelpNavigation } from "./components/GoBackFindHelpNavigation";
import Layout from "./components/Layout";
import { WithCanteenId } from "./components/WithCanteenId";
import { CanteenId } from "./inputViews/CanteenId";
import { Email } from "./inputViews/Email";
import { InvitationBlock } from "./inputViews/InvitationBlock";
import { Password } from "./inputViews/Password";
import { ProvidersPick } from "./inputViews/ProvidersPick";
import { SchoolFromTree } from "./inputViews/School";
import { SchoolConfirmation } from "./inputViews/SchoolConfirmation";
import { Selectors } from "./inputViews/Selectors";
import { Switch } from "./inputViews/Switch";
import { Username } from "./inputViews/Username";
import { WorkEmailAndLocation } from "./inputViews/WorkEmailAndLocation";

// FLOW CONTROL

/** Definition of steps and their parameters (to minimize shared state) */
export type Step =
  | { type: "invitation-block" }
  | { type: "switch" }
  | { type: "school"; groupId?: string; email?: string }
  | {
      type: "selectors";
      schoolId: string;
      selectedSelectors?: ChildSelector;
      email: string;
    }
  | { type: "workEmail" }
  | { type: "email"; schoolId: string }
  | { type: "username" }
  | { type: "password" }
  | { type: "canteenId" }
  | {
      type: "schoolConfirmation";
      school: { name: string; id: string };
      scope?: Scope;
    }
  | {
      type: "provider";
      email: string;
      schoolId: string;
      selectors?: ChildSelector;
    };

type CurrentViewProps = {
  /** Current step of the flow */
  step: Step;
  /** Function to push a new step */
  pushStep: (step: Step) => void;
  /** Replaces the last step with a new step */
  replaceStep: (step: Step) => void;
  /** Function to go back to the previous step */
  popStep: () => void;
  /** Data collected, used to create a new user */
  formData: Partial<CreateChildUserPropsType>;
  /** Function to (partially) update the `formData` */
  updateFormData: (values: Partial<CreateChildUserPropsType>) => void;
  /** Called when a user should be created */
  createUser: (formData: CreateChildUserPropsType) => void;
};

/**
 * Helper component, displays view of the current `step` in the CreateUser flow
 * Documented in {@link apps/frontend/components/signup/signup.drawio.svg}
 */
function CurrentView({
  step,
  pushStep,
  replaceStep,
  popStep,
  formData,
  updateFormData,
  createUser,
}: CurrentViewProps): JSX.Element {
  const currentLocale = tx.getCurrentLocale();
  const navigateToCanteenId = () => pushStep({ type: "canteenId" });
  switch (step?.type) {
    case "switch":
      return (
        <WithCanteenId navigate={navigateToCanteenId}>
          <Switch
            onEducation={() => {
              pushStep({ type: "school" });
            }}
            onWork={() => {
              pushStep({ type: "workEmail" });
            }}
          />
        </WithCanteenId>
      );
    case "workEmail": {
      const onEmailInput = (email: string) => {
        updateFormData({ email });
        pushStep({ type: "school", email });
      };
      return (
        <WithCanteenId navigate={navigateToCanteenId}>
          <WorkEmailAndLocation
            onSubmit={onEmailInput}
            emailValue={formData.email}
          />
        </WithCanteenId>
      );
    }
    case "email": {
      const onEmailInput = (email: string) => {
        updateFormData({ email });
        pushStep({ type: "selectors", email, schoolId: step.schoolId });
      };

      return <Email onSubmit={onEmailInput} emailValue={formData.email} />;
    }
    case "username":
      return (
        <Username
          onSubmit={(values) => {
            updateFormData({ username: values.username });
            pushStep({ type: "password" });
          }}
          usernameValue={formData.username}
        />
      );
    case "school": {
      const { groupId, email } = step;
      const getNextStep = (schoolId: string): Step => {
        if (!email) {
          return { type: "email", schoolId };
        }
        return { type: "selectors", email, schoolId };
      };
      return (
        <SchoolFromTree
          onSchoolSelect={(schoolId) => {
            updateFormData({ schoolId });
            const nextStep = getNextStep(schoolId);
            pushStep(nextStep);
          }}
          onGroupSelect={(groupId) => {
            // Repeat this step with new groupId
            pushStep({ ...step, groupId });
          }}
          onGroupAutoSelect={(groupId) => {
            // Group is auto-selected, replace step to allow going back to the previous step
            replaceStep({ ...step, groupId });
          }}
          groupId={groupId}
          email={step.email}
        />
      );
    }
    case "password": {
      const schoolId = formData.schoolId;
      if (!schoolId) {
        // This will never happen, if our flow is correct.
        return <T _str="ERROR: Location was not selected" />;
      }
      return (
        <Password
          passwordValue={formData.password}
          onSubmit={(values) =>
            createUser({
              ...formData,
              password: values.password,
              locale: currentLocale,
            } as CreateChildUserPropsType)
          }
          schoolId={schoolId}
        />
      );
    }
    case "selectors": {
      const { schoolId, email } = step;

      const getNextStep = (selectors?: ChildSelector): Step => ({
        type: "provider",
        email,
        schoolId,
        selectors,
      });

      return (
        <Selectors
          schoolId={schoolId}
          email={email}
          selectedSelectors={step.selectedSelectors ?? {}}
          onSelectorSelect={(selectedSelectors) => {
            // Repeat this step with new selectedSelectors
            pushStep({
              ...step,
              selectedSelectors,
            });
          }}
          onAllSelectorsSelected={(selectors) => {
            // All selectors selected, move to the next step
            updateFormData({ selectors });
            const nextStep = getNextStep(selectors);
            pushStep(nextStep);
          }}
          onSkip={() => {
            const nextStep = getNextStep();
            replaceStep(nextStep);
          }}
        />
      );
    }
    case "canteenId":
      return (
        <CanteenId
          onSubmit={({ school, scope }) => {
            // defer formData update until the user confirms the school
            pushStep({ type: "schoolConfirmation", school, scope });
          }}
        />
      );
    case "schoolConfirmation": {
      const {
        school: { id: schoolId, name },
        scope,
      } = step;
      const handleCorrect = () => {
        updateFormData({ schoolId, scope });
        pushStep({ type: "email", schoolId });
      };
      return (
        <SchoolConfirmation
          selectedLocation={name}
          onCorrect={handleCorrect}
          onIncorrect={() => popStep()}
        />
      );
    }
    case "provider": {
      const { email, schoolId, selectors } = step;

      return (
        <ProvidersPick
          email={email}
          schoolId={schoolId}
          selectors={selectors}
          onSkip={() => {
            replaceStep({ type: "username" });
          }}
        />
      );
    }
    case "invitation-block":
      return (
        <WithCanteenId navigate={navigateToCanteenId}>
          <InvitationBlock />
        </WithCanteenId>
      );
  }
}

/**
 * Component responsible for creating the user (and handling the flow)
 */
export default function CreateUser({ intialStep }: { intialStep: Step }) {
  // Flow controls implemented as a stack, which makes it easy to go back and forth
  const steps = useStack([intialStep]);

  // Data used to create the user
  const [formData, setFormData] = useState<Partial<CreateChildUserPropsType>>(
    {}
  );
  const updateFormData = (values: Partial<CreateChildUserPropsType>) => {
    setFormData((prev) => ({ ...prev, ...values }));
  };

  const router = useRouter();
  const { auth } = useContainer(AppContext);
  const { isLoading: isCreatingUser, mutate: createUserMutate } = useMutation({
    mutationFn: async (formData: CreateChildUserPropsType) => {
      const user = await callInternalApi<
        CreateChildUserPropsType,
        CreateChildUserReturnType
      >("user/new", formData);
      if (!user) {
        throw new Error("Failed to create user");
      }

      await auth.signInWithToken(user?.token);
      await router.push(`/signup/onboarding/${user.child.id}`);
    },
  });

  if (!steps.top) {
    router.back();
    return null;
  }

  return (
    <Layout>
      {isCreatingUser ? (
        <Layout.Inner>
          <div className="h-screen flex flex-col justify-center items-center">
            <p className="title-secondary mb-4 -mt-24">
              <T _str="We're creating your account" />
            </p>
            <Spinner />
          </div>
        </Layout.Inner>
      ) : (
        <>
          <GoBackFindHelpNavigation
            onGoBack={steps.stack.length > 1 ? steps.pop : undefined}
          />
          <Layout.Inner>
            <CurrentView
              step={steps.top}
              pushStep={steps.push}
              replaceStep={steps.replace}
              popStep={steps.pop}
              formData={formData}
              updateFormData={updateFormData}
              createUser={(formData) => {
                createUserMutate(formData);
              }}
            />
          </Layout.Inner>
        </>
      )}
    </Layout>
  );
}
