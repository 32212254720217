import { Providers } from "@kanpla/types";
import { CreateChildUserPropsType } from "apps/frontend/pages/api/internal/user/new";

export type ChildWithProvider = Pick<
  CreateChildUserPropsType,
  "email" | "schoolId" | "selectors"
> & { provider: Pick<Providers, "name" | "tenantId"> };

const SESSION_KEY = "PROVIDER_SIGNUP_INIT";

export function getProviderSignupInit() {
  return JSON.parse(
    sessionStorage.getItem(SESSION_KEY) || "null"
  ) as ChildWithProvider | null;
}

export function setProviderSignupInit(data: ChildWithProvider) {
  sessionStorage.setItem(SESSION_KEY, JSON.stringify(data));
}
