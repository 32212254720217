import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBuildings, faSchool } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T, useT } from "@kanpla/system";
import classNames from "classnames";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import { Heading } from "../components/Layout";

function useAppName() {
  const { supplier, supplierLoading } = useContainer(AppContext);
  return [
    supplier?.name || supplier?.appName || "Kanpla",
    supplierLoading,
  ] as const;
}

type Props = {
  onEducation: () => void;
  onWork: () => void;
};

export const Switch = ({ onEducation, onWork }: Props) => {
  const t = useT();
  const [appName, isLoading] = useAppName();
  if (isLoading) return null;

  return (
    <div className="flex flex-col gap-4">
      <Heading>
        <T _str="Where will you use {appName}?" appName={appName} />
      </Heading>
      <div className="flex items-center w-full gap-x-6">
        <Button
          onClick={onEducation}
          icon={faSchool}
          title={t("Education")}
          dataCy="signup-school-btn"
        />
        <Button
          onClick={onWork}
          icon={faBuildings}
          title={t("Work")}
          dataCy="signup-school-btn"
        />
      </div>
    </div>
  );
};

type ButtonProps = {
  onClick: () => void;
  icon: IconProp;
  title: string;
  dataCy: string;
};

const Button = ({ onClick, icon, title, dataCy }: ButtonProps) => (
  <button
    className={classNames(
      "w-36 h-36 rounded-md border-2 border-secondary-main",
      "transition duration-75 ease-in-out",
      "hover:border-primary-main hover:text-primary-main hover:shadow-sm",
      "flex flex-col justify-center items-center gap-2 group"
    )}
    onClick={onClick}
    data-cy={dataCy}
  >
    <FontAwesomeIcon
      icon={icon}
      size="2x"
      className="text-text-secondary group-hover:text-primary-main"
    />
    <p className="text-lg">{title}</p>
  </button>
);
