import { zodResolver } from "@hookform/resolvers/zod";
import { T, callInternalApi, getErrorMessage, t, useT } from "@kanpla/system";
import { Button, Input } from "@kanpla/ui";
import { useQueryClient } from "@tanstack/react-query";
import {
  EmailValidationError,
  EmailValidationProps,
  EmailValidationResponse,
} from "apps/frontend/pages/api/internal/signup/emailValidation";
import { URL_LOGIN } from "apps/frontend/pages/login";
import Link from "next/link";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { InputWithMessage } from "../components/InputWithMessage";
import { Heading } from "../components/Layout";
import { Err, Ok, Result } from "../util/Result";

// Validation
export const EMAIL_SCHEMA = z
  .string()
  .refine(
    (val) =>
      new RegExp(
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      ).test(val),
    { message: t("Please use a valid email.") }
  );
const FORM_SCHEMA = z.object({
  email: EMAIL_SCHEMA,
});
type FormFields = z.infer<typeof FORM_SCHEMA>;

type EmailErrorType = EmailValidationError | "INTERNAL";
type EmailError = { type: EmailErrorType; message?: string };

const EmailErrorMessage = ({
  error,
  loginUrl = URL_LOGIN,
}: {
  error: EmailError;
  loginUrl?: string;
}) => {
  const t = useT();

  if (error.message) {
    return <span>{error.message}</span>;
  }

  switch (error.type) {
    case "EMAIL_EXISTS":
      return (
        <>
          <T _str="This email is already registered." />{" "}
          <Link href={loginUrl}>{t("Log in")}</Link>
        </>
      );
    case "INVALID_EMAIL":
      return <T _str="Invalid email address" />;
    case "BLOCKED_DOMAIN":
      return <T _str="This domain is blocked. Use different email." />;
    default:
      return <T _str="Something went wrong" />;
  }
};

type EmailProps = {
  /** Called when the email is submitted. */
  onSubmit: (email: string) => void;
  emailValue?: string;
  loginUrl?: string;
};

/**
 * Collects an email address.
 */
export const Email = ({ onSubmit, emailValue = "", loginUrl }: EmailProps) => {
  const t = useT();
  const { handleSubmit, register, formState, setError, watch } =
    useForm<FormFields>({
      resolver: zodResolver(FORM_SCHEMA),
      mode: "onSubmit",
      values: { email: emailValue },
    });

  // Get email validation function
  const validateEmail = useEmailValidation();

  const submitHandler = async (formData: FormFields) => {
    const response = await validateEmail(formData.email);
    if (response.isErr) {
      setError("email", response.error);
      return;
    }
    onSubmit(formData.email);
  };

  const { isValidating, isSubmitting } = formState;

  const emailError = formState.errors.email as EmailError | undefined;

  return (
    <form
      className="flex flex-col gap-4"
      onSubmit={handleSubmit(submitHandler)}
    >
      <Heading>
        <T _str="Write your email" />
      </Heading>
      {/* maybe extract Input.Email + errors + useEmailValidation in ../components/email ? */}
      <InputWithMessage
        input={
          <Input.Email
            placeholder={t("Enter your email address")}
            required
            dataCy="new-email-signup"
            error={!!formState.errors.email}
            value={watch("email")} // value is necessary for mobile input label
            {...register("email")}
          />
        }
        isError={!!emailError}
        message={
          emailError ? (
            <EmailErrorMessage error={emailError} loginUrl={loginUrl} />
          ) : null
        }
      />
      <Button
        htmlType="submit"
        type="primary"
        shape="soft"
        disabled={watch("email") === ""}
        loading={isValidating || isSubmitting}
      >
        <T _str="Continue" />
      </Button>
    </form>
  );
};

/**
 * @returns Function that validates an email address and returns the
 * {@link EmailError} if any.
 */
function useEmailValidation() {
  const client = useQueryClient();

  const validateEmail = async (
    email: string
  ): Promise<Result<void, EmailError>> => {
    let response: EmailValidationResponse | undefined;
    try {
      response = await client.fetchQuery({
        queryKey: ["signup/emailValidation", email],
        queryFn: () => {
          return callInternalApi<EmailValidationProps, EmailValidationResponse>(
            "signup/emailValidation",
            {
              email,
            }
          );
        },
      });
    } catch (error) {
      return Err({ type: "INTERNAL", message: getErrorMessage(error) });
    }
    if (!response) return Err({ type: "INTERNAL" });
    if (response.isErr) return Err({ type: response.error });

    return Ok();
  };

  return validateEmail;
}
