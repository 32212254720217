import {
  faCheck,
  faTimes,
  faWarning,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { zodResolver } from "@hookform/resolvers/zod";
import { T, callInternalApi, t } from "@kanpla/system";
import { Partner, School } from "@kanpla/types";
import {
  Button,
  Checkbox,
  Input,
  PrivacyPolicy,
  Spinner,
  TermsOfTrade,
} from "@kanpla/ui";
import { useQuery } from "@tanstack/react-query";
import {
  GetPaymentGatewayIdArgs,
  GetPaymentGatewayIdResponse,
} from "apps/frontend/pages/api/internal/signup/getPaymentGatewayId";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { InputWithMessage } from "../components/InputWithMessage";
import { Heading } from "../components/Layout";

const MIN_PASSWORD_LENGTH = 8;
const FORM_SCHEMA = z.object({
  password: z.string().min(MIN_PASSWORD_LENGTH, {
    message: t("Must be at least {min} characters", {
      min: MIN_PASSWORD_LENGTH,
    }),
  }),
  hasAcceptedTerms: z.boolean().refine((hasAccepted) => hasAccepted, {
    message: t(
      "You must accept the Terms of Use and Privacy Policy to use Kanpla."
    ),
  }),
});

export type PasswordFormType = z.infer<typeof FORM_SCHEMA>;

interface Props {
  onSubmit: (values: PasswordFormType) => void;
  passwordValue?: string;
  /** Necessary for loading the correct PaymentGatewayId which is used for Terms of Use & Privacy Policy links */
  schoolId?: School["id"];
  /** Necessary for loading the correct PaymentGatewayId which is used for Terms of Use & Privacy Policy links */
  partnerId?: Partner["id"];
}
export const Password = ({
  passwordValue,
  onSubmit,
  schoolId,
  partnerId,
}: Props) => {
  const query = useQuery({
    queryKey: ["signup/getPaymentGatewayId", { schoolId, partnerId }],
    queryFn: () => {
      return callInternalApi<
        GetPaymentGatewayIdArgs,
        GetPaymentGatewayIdResponse
      >("signup/getPaymentGatewayId", {
        schoolId,
        partnerId,
      });
    },
  });

  const { handleSubmit, register, formState, watch } =
    useForm<PasswordFormType>({
      resolver: zodResolver(FORM_SCHEMA),
      values: {
        password: passwordValue || "",
        hasAcceptedTerms: false,
      },
    });

  const { isValidating, isSubmitting, errors } = formState;
  const acceptedTermsError = errors.hasAcceptedTerms;
  const passwordError = errors.password;
  const passwordIsValid = watch("password").length >= MIN_PASSWORD_LENGTH;

  if (query.isLoading) return <Spinner />;
  // If the query fails, just use undefined as the paymentGatewayId
  const paymentGatewayId = query.data ?? undefined;

  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
      <Heading>
        <T _str="Choose a password" />
      </Heading>
      <InputWithMessage
        input={
          <Input.Password
            required
            error={!!passwordError}
            value={watch("password")} // value is necessary for mobile input label
            {...register("password")}
          />
        }
        isError={!!passwordError}
        isValid={passwordIsValid}
        message={
          <>
            <FontAwesomeIcon
              icon={
                passwordError ? faWarning : passwordIsValid ? faCheck : faTimes
              }
              className="ml-3 mr-1.5"
            />
            {passwordError?.message ?? (
              <T _str="Must be at least 8 characters" />
            )}
          </>
        }
      />
      <InputWithMessage
        className="mt-8"
        input={
          <Checkbox
            {...register("hasAcceptedTerms")}
            checked={watch("hasAcceptedTerms")}
          >
            <T
              _str="I accept {termsOfUse} and {privacyPolicy}"
              termsOfUse={<TermsOfTrade paymentGatewayId={paymentGatewayId} />}
              privacyPolicy={
                <PrivacyPolicy paymentGatewayId={paymentGatewayId} />
              }
            />
          </Checkbox>
        }
        isError={!!acceptedTermsError}
        message={acceptedTermsError?.message}
      />

      <Button
        htmlType="submit"
        type="primary"
        shape="solid"
        loading={isValidating || isSubmitting}
      >
        <T _str="Continue" />
      </Button>
    </form>
  );
};
